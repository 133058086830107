import {
  get,
  put,
  post,
  Delete
} from "../ApiDecorator"; // 倒入 api
// 单独倒出
const Wjx = {
  // 问卷星-获取问卷类型 
  atypeList() {
    return post("/quest/wjx/atypeList");
  },
  // 问卷星-创建并获取跳转链接 
  createWjx(data) {
    return post("/quest/wjx/createWjx", data)
  },
  // 问卷星-编辑并获取跳转链接 
  editWjx(data) {
    return put("/quest/wjx/editWjx",data)
  },
  // 问卷星-获取首师优字用户组 
  getYzData() {
    return get("/quest/wjx/getYzData")
  },
  // 查询问卷列表 - 管理员
  listAdmin(data) {
    return get("/quest/wjx/listAdmin", data)
  },
  // 查询问卷列表 - 专家
  listExpert(data) {
    return get("/quest/wjx/listExpert", data)
  },
  // 删除问卷
  deleteQuest(data) {
    return Delete("/quest/wjx/delete", data)
  },
  // 问卷星-获取操作url 
  getLoginUrl(data) {
    return get("/quest/wjx/getLoginUrl", data)
  },
  // 问卷星-同步数据
  syncWjx(data) {
    return get("/quest/wjx/syncWjx", data)
  },
  // 问卷星-参训教师获取答题链接 /quest/wjx/goAnswer
  goAnswer(data) {
    return get("/quest/wjx/goAnswer", data)
  },
  //  查询问卷列表 - 教师 
  listTeacher(data) {
    return get("/quest/wjx/listTeacher", data)
  },
  // 问卷星-查询单个问卷信息
  getById(data){
    return get("/quest/wjx/getById",data)
  },
  // 问卷星-生成文件
  create(data){
    return get("/quest/wjx/export/create",data)
  },
  // 问卷星-检测状态
  checkStatus(data){
    return get("/quest/wjx/export/check",data)
  }
};

// 默认全部倒出
export default Wjx;