<template>
  <!-- 选择用户组 组件 -->
  <div class="">
    <el-form-item label="所属项目：" required>
      <div class="flex-layout">
        <el-form-item prop="trancheId" class="period-select">
          <el-select v-model="trancheId" placeholder="请选择" @change="periodChange" :disabled="isDisabled">
            <el-option v-for="item in periodList" :key="item.value" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="projectId" class="project-select" style="margin-left:20px">
          <el-cascader :options="projectList" :props="{ checkStrictly: true, value: 'id', label: 'name'}"
            v-model="projectId" @change="projectChange" :disabled="isDisabled"></el-cascader>
        </el-form-item>
      </div>
    </el-form-item>
    <el-form-item label="用户组：" prop="userGroupIds" ref="bindUserGroup" :rules="userGroupRules">
      <el-cascader :options="userGroup" :props="{ multiple: true, value: 'id',emitPath:false}" clearable filterable
        v-model="userGroupIds" ref="cascader" @change="userGroupChange" collapse-tags
        :disabled="isDisabled">
      </el-cascader>
    </el-form-item>
  </div>
</template>

<script>
  export default {
    props: {
      // 二级项目ID
      id: {
        type: Number,
      },
      proId: {
        type: Number
      },
      userGroupVal: {
        type: Array
      },
      isBoutique: {
        type: Number,
      },
      isEdit: {
        type: Boolean,
      },
    },
    data() {
      return {
        periodList: [],
        trancheId: '',
        projectList: [],
        projectId: [],
        userGroup: [],
        userGroupIds: '',
        userGroupArray: '',
        isDisabled: false,
        trancheRules: {
          required: true,
          message: '请选择期次',
          trigger: 'change',
        },
        projectRules: {
          required: true,
          message: '请选择所属项目',
          trigger: 'change',
        },
        userGroupRules: {
          required: true,
          message: "请选择用户组",
          trigger: "blur"
        }
      }
    },
    computed: {},
    watch: {
      proId(newVal, oldVal) {
        if (!newVal) return;
        this.projectId = newVal;
      },
      userGroupVal(newVal, oldVal) {
        if (!newVal || JSON.stringify(newVal) == '[]') return
        this.userGroupIds = this.userGroupVal
      },
      isBoutique(newVal, oldVal) {
        // 为 0
        if (!newVal) {
          // 不置灰
          this.isDisabled = false;
          if (this.isEdit && !oldVal) return;
          this.userGroupIds = [];
          this.userGroupChange();
          return;

        }
        // 为 1 
        let options = this.projectChange([this.projectId]);
        Promise.all([options]).then((values) => {
          this.userGroupIds = this.allValues();
          this.isDisabled = true;
          this.userGroupChange();
        });
      },
    },
    methods: {
      async getPeriod() {
        let resData = await this.$Api.Form.getPeriod(this.id)
        // console.log(resData);
        this.periodList = resData.data;
        this.trancheId = resData.data[0].id
        this.projectList = resData.data[0].children;
        this.projectId = this.projectList[0].id
        this.projectChange([this.projectId]);
      },
      //绑定项目期次变化 获取对应 cascader
      periodChange(val) {
        let arr = this.periodList.filter(item => {
          return item.id == val
        })
        this.projectList = arr[0].children
      },
      //根据项目期次获取对应 cascader 获取对应的用户组
      projectChange(val) {
        if (!val || JSON.stringify(val) == '[]') return;
        this.projectId = val[val.length - 1];
        return this.$Api.Form.getUserGroup(val[val.length - 1])
          .then(res => {
            this.userGroup = res.data;
            return new Promise((resolve, reject) => {
              resolve(this.userGroup)
            })
          })
          .catch(err => console.log(err));

      },
      //监听用户组变化(获取用户组id数组用于传参)
      userGroupChange(val) {
        // console.log(val);
        // console.log(this.userGroupIds);
        // this.userGroupArray = JSON.stringify(val);
        // let userGroups = this.$refs["cascader"].getCheckedNodes();
        // let userGroupArr = [];
        // userGroups.map(item => {
        //   userGroupArr.push(item.value);
        // });
        this.$emit('userGroup', {
          trancheId: this.trancheId,
          projectId: this.projectId,
          // userGroupId: userGroupArr.join(','),
          userGroupArr: this.userGroupIds,
          // userGroupArr: userGroupArr,
        })
        // this.userGroupId = userGroupArr.join(',');
      },
      // 用户组全选
      allValues() {
        let model = [];

        function tree2arr(arr, str, level) {
          arr.forEach(it => {
            let newStr = str.length ? [...str, it.id] : it.id;
            if (it.children) {
              tree2arr(it.children, newStr, level + 1)
            } else {
              model.push(newStr)
            }
          })
        }
        tree2arr(this.userGroup, [], 0)
        return model;
      }
    },
    mounted() {

      if (!this.id) return;
      this.$nextTick(() => {
        this.getPeriod();
      })
    },
  }
</script>

<style lang="less" scoped>
  /deep/.el-form-item {
    margin-bottom: 23px;
  }

  .period-select,
  .project-select {
    margin-bottom: 0 !important;
  }

  .project-select /deep/.el-input {
    width: 250px;
  }
</style>