// 中心首页
const test = () =>
    import("../views/test.vue"); //模拟高考 测试
const notFound = () =>
    import("../views/404.vue"); //模拟高考 测试
const Home = () =>
    import("../views/home/home.vue"); //中心首页
const ItemList = () =>
    import("../views/home/item_list.vue"); //首页
const mainPageEnter = () =>
    import("../views/project/mainPage-entrance.vue"); //项目主页入口
const mainPage = () =>
    import("../views/project/mainPage.vue"); //项目主页
const Schedule = () =>
    import("../views/schedule/schedule_home.vue"); //项目概况
const LeaderHome = () =>
    import("../views/leader/leader_home.vue"); //领导首页（领导--项目概况）
const LeaderActiveReport = () =>
    import("../views/leader/leadeer_report_entrance.vue"); //领导活动报告
const LeaderData = () => import("../views/leader/leader_data.vue"); //领导数据大屏
const Team = () =>
    import("../views/other/team.vue"); //教学团队
const Course = () =>
    import("../views/course/course_home.vue"); //我的课程 同步课
const CourseAsync = () =>
    import("../views/course/course_asynclist.vue"); //我的课程 异步课
const InternetCourse = () =>
    import("../views/course/course_internet.vue"); //网络课程
const News = () =>
    import("../views/news/news_list.vue"); //项目动态
const Mytask = () =>
    import("../views/course/myTask_home.vue"); //我的任务  （我的作业）
const MineActivity = () =>
    import("../views/course/myActivity_home.vue"); //我的活动
// const CloudQuestion = () =>
//     import ("../views/other/question.vue"); //云端答疑
const CloudQuestion = () =>
    import("../views/other/question-home.vue"); //云端答疑
const Abilityreport = () =>
    import("../views/ability/report_composite.vue"); //能力报告
const Evaluate = () =>
    import("../views/evaluate/evaluate_home.vue"); //综合评价
const Explain = () => import("../views/other/explain-home.vue"); //答疑解惑
const WjxTeaList = () => import("../views/course/wjx_list.vue"); //问卷星列表
const YXSpace = () =>
    import("../views/other/space-home.vue"); //云端答疑


const TeachManage = () =>
    import("../views/manage/manage_home.vue"); //教学管理
const Project = () =>
    import("../views/manage/manage_project/project_home.vue"); //教学管理 / 项目课管理
const Public = () =>
    import("../views/manage/manage_public/public_home.vue"); //教学管理 / 公开课管理
const Activity = () =>
    import("../views/manage/manage_activity/activity_home.vue"); //教学管理 / 活动管理
const Job = () =>
    import("../views/manage/manage_work/job_home.vue"); //教学管理 / 作业管理
const signIn = () =>
    import("../views/manage/manage_signIn/signIn_home.vue"); //教学管理 / 作业管理
const Salon = () =>
    import("../views/manage/manage_salon/salon_home.vue"); //教学管理 / 研讨沙龙
const credit = () =>
    import("../views/my/my_credit.vue"); // 教师-我的学分

// 问卷星
const Wjx = () => import("../views/manage/manage_wjx/wjx_home.vue") // 问卷星home
const WjxCreate = () => import("../views/manage/manage_wjx/wjx_create.vue") // 问卷星创建
const Space = ()=>import("../views/manage/manage_space/space.vue")// 研训空间(K吧)
const TeamManage = ()=>import("../views/manage/manage_team/team.vue") // 团队管理



//管理者 
const Weekly = () =>
    import("../views/manager/weekly.vue"); //周报管理
const Reivew = () =>
    import("../views/manager/review.vue"); //回顾管理
const ExamQuest = () =>
    import("../views/manager/examination.vue"); //习题管理
const totalActivity = () =>
    import("../views/manager/activity.vue"); //活动管理
const totalCourse = () =>
    import("../views/manager/course.vue"); //项目课管理
const totalWork = () =>
    import("../views/manager/work.vue"); //作业管理
const Personnel = () =>
    import("../views/manager/personnel.vue"); //人员统计
const CourseStatistics = () =>
    import("../views/manager/course-stats.vue"); //课程统计
const WorkStatistics = () =>
    import("../views/manager/work-stats.vue"); //作业统计
const AttendanceStatistics = () =>
    import("../views/manager/attendance-stats.vue"); //考勤统计
const ExcellentworkStatistics = () =>
    import("../views/manager/excellentwork-stats.vue"); //优秀作业统计
const score = () =>
    import("../views/manager/score.vue") // 管理员-学分管理
const scoreStatistics = () =>
    import("../views/manager/score-stats.vue") //管理员-学分统计
const totalSalon = () =>
    import("../views/manager/salon.vue") //管理员-沙龙管理
const courseAI = () => import("../views/course_report.vue")


export default [{
    path: "/home",
    name: "home",
    component: Home,
    redirect: {
        name: "schedule"
    }, // 跳转到下一级第一个
    meta: {
        title: "研修空间",
        keepAlive: false
    },
    children: [{
        path: "schedule",
        name: "schedule",
        component: Schedule,
        meta: {
            title: "项目概况",
            keepAlive: false
        }
    },
    {
        path: "course",
        name: "course",
        component: Course,
        meta: {
            title: "同步课程",
            keepAlive: true
        }
    },
    {
        path: "courseAsync",
        name: "courseAsync",
        component: CourseAsync,
        meta: {
            title: "异步课程",
            keepAlive: true
        }
    },
    {
        path: "internetCourse",
        name: "internetCourse",
        component: InternetCourse,
        meta: {
            title: "网络课程",
            keepAlive: true
        }
    },
    {
        path: "mytask",
        name: "mytask",
        component: Mytask,
        meta: {
            title: "我的作业",
            keepAlive: true
        },
        children: []
    },
    {
        path: "myActivity",
        name: "mineActivity",
        component: MineActivity,
        meta: {
            title: "我的活动",
            keepAlive: true
        }
    },
    {
        path: "WjxTeaList",
        name: 'WjxTeaList',
        component: WjxTeaList,
        meta: {
            title: "调研问卷",
            keepAlive: true
        }
    },
    {
        path: "space",
        name: "space",
        component: YXSpace,
        meta: {
            title: "研训空间",
            keepAlive: true
        }
    },
    {
        path: "question",
        name: "question",
        component: CloudQuestion,
        meta: {
            title: "云端答疑",
            keepAlive: true
        }
    },
    {
        path: "report",
        name: "abilityreport",
        component: Abilityreport,
        meta: {
            title: "能力报告",
            keepAlive: false
        }
    },
    {
        path: "evaluate",
        name: "evaluate",
        component: Evaluate,
        meta: {
            title: "综合评价",
            keepAlive: true
        },
    },
    {
        path: "leader_home",
        name: "leader_home",
        component: LeaderHome,
        meta: {
            title: "项目概况",
            keepAlive: true
        }
    },
    {
        path: "leader_report",
        name: "leader_report",
        component: LeaderActiveReport,
        meta: {
            title: "活动报告",
            keepAlive: true
        }
    },
    {
        path: "project_data",
        name: "project_data",
        component: LeaderData,
        meta: {
            title: "项目数据",
            keepAlive: false
        }
    },
    {
        path: "team",
        name: "team",
        component: Team,
        meta: {
            title: "教学团队",
            keepAlive: false
        }
    },
    {
        path: "manage",
        name: "manage",
        component: TeachManage,
        meta: {
            title: "教学管理",
            keepAlive: true
        },
        redirect: {
            name: "project"
        },
        children: [{
            path: 'project',
            name: 'project',
            component: Project,
            meta: {
                title: '项目课管理',
                keepAlive: true
            }
        }, {
            path: 'public',
            name: 'public',
            component: Public,
            meta: {
                title: '公开课管理',
                keepAlive: true
            }
        }, {
            path: 'activity',
            name: 'activity',
            component: Activity,
            meta: {
                title: '活动管理',
                keepAlive: true
            }
        }, {
            path: 'job',
            name: 'job',
            component: Job,
            meta: {
                title: '作业管理',
                keepAlive: false
            }
        }, {
            path: 'signIn',
            name: 'signIn',
            component: signIn,
            meta: {
                title: '签到管理',
                keepAlive: true
            }
        }, {
            path: 'salon',
            name: 'salon',
            component: Salon,
            meta: {
                title: '研讨沙龙',
                keepAlive: true
            }
        }, {
            path: 'wjx',
            name: 'wjx',
            component: Wjx,
            meta: {
                title: '调研问卷',
                keepAlive: false
            }
        }, {
            path: 'space',
            name: 'space',
            component:Space,
            meta: {
                title: '研训空间',
                keepAlive: false
            }
        },{
            path: 'team_manage',
            name: 'team_manage',
            component:TeamManage,
            meta: {
                title: '团队管理',
                keepAlive: false
            }
        }]
    },
    {
        path: "news",
        name: "news",
        component: News,
        meta: {
            title: "项目动态",
            keepAlive: false
        }
    },
    {
        path: "weekly",
        name: "weekly",
        component: Weekly,
        meta: {
            title: "周报管理",
            keepAlive: true
        }
    },
    {
        path: "reivew",
        name: "reivew",
        component: Reivew,
        meta: {
            title: "回顾管理",
            keepAlive: true
        }
    },
    {
        path: "examQuest",
        name: "examQuest",
        component: ExamQuest,
        meta: {
            title: "习题管理",
            keepAlive: true
        }
    },
    {
        path: "totalActivity",
        name: "totalActivity",
        component: totalActivity,
        meta: {
            title: "活动管理",
            keepAlive: true
        }
    },
    {
        path: "totalCourse",
        name: "totalCourse",
        component: totalCourse,
        meta: {
            title: "项目课管理",
            keepAlive: true
        }
    },
    {
        path: "totalWork",
        name: "totalWork",
        component: totalWork,
        meta: {
            title: "作业管理",
            keepAlive: true
        }
    },
    {
        path: "personnel",
        name: "personnel",
        component: Personnel,
        meta: {
            title: "人员统计",
            keepAlive: true
        }
    },
    {
        path: "courseStatistics",
        name: "courseStatistics",
        component: CourseStatistics,
        meta: {
            title: "课程统计",
            keepAlive: true
        }
    },
    {
        path: "workStatistics",
        name: "workStatistics",
        component: WorkStatistics,
        meta: {
            title: "作业统计",
            keepAlive: true
        }
    },
    {
        path: "attendanceStatistics",
        name: "attendanceStatistics",
        component: AttendanceStatistics,
        meta: {
            title: "考勤统计",
            keepAlive: true
        }
    },
    {
        path: "excellentworkStat",
        name: "excellentworkStatistics",
        component: ExcellentworkStatistics,
        meta: {
            title: "优秀作业统计",
            keepAlive: true
        }
    },
    {

        path: "explain",
        name: "explain",
        component: Explain,
        meta: {
            title: "答疑解惑",
            keepAlive: true
        }
    },
    {
        path: "credit",
        name: "credit",
        component: credit,
        meta: {
            title: "我的学分",
            keepAlive: false
        }
    },
    {
        path: "score",
        name: "score",
        component: score,
        meta: {
            title: "学分管理",
            // bread:true,
            keepAlive: false
        }
    },
    {
        path: "totalSalon",
        name: "totalSalon",
        component: totalSalon,
        meta: {
            title: "沙龙管理",
            // bread:true,
            keepAlive: false
        }
    },
    {
        path: "scoreStatistics",
        name: "scoreStatistics",
        component: scoreStatistics,
        meta: {
            title: "学分统计",
            keepAlive: false
        }
    }

    ]
}, {
    path: "/item-list",
    name: "item-list",
    component: ItemList,
    meta: {
        title: "项目列表页",
        keepAlive: false,
        // hasNotHeader:true,
    },
},
{
    path: "/main/:id",
    name: "mainPageEnter",
    component: mainPageEnter,
    meta: {
        title: "项目主页",
        keepAlive: false
    },
    redirect: {
        name: "mainPage"
    },
    children: [{
        path: "project",
        name: "mainPage",
        component: mainPage,
        meta: {
            title: "项目主页",
            keepAlive: false
        }
    }]
},
{
    path: "/test",
    name: "test",
    component: test,
    meta: {
        title: "测试表单提交",
        keepAlive: true
    },
},
{
    path: "*",
    name: "notFound",
    component: notFound,
    meta: {
        title: "404",
        keepAlive: false
    },
},

{
    path: '/courseAI',
    name: 'courseAI',
    component: courseAI,
    meta: {
        title: "AI课堂评价",
        hasNotHeader: true,
    }
}
]